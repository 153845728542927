import axios from 'axios';

//actions types

export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';

//action creaters

export const fetchItemsRequest = () => {
    return {
        type: FETCH_ITEMS_REQUEST,
    }
}

export const fetchItemsSuccess = (items) => {
    return {
        type: FETCH_ITEMS_SUCCESS,
        payload:items,
    }
}

export const fetchItemsFailure = (error) => {
    return {
        type: FETCH_ITEMS_FAILURE,
        payload:error,
    }
}

//asyncronous actions to fetch items


export const fetchItems = () => {
    return async (dispatch) => {
        dispatch(fetchItemsRequest());
        try {
            const response = await axios.get('http://51.20.70.58:8000/api/product-list/');
            dispatch(fetchItemsSuccess(response.data))
        } catch (error) {
            dispatch(fetchItemsFailure(error.message))
        }
    }
}


import React from 'react'
import './item.css'
import { useNavigate } from 'react-router-dom';

function Item({item}) {

  const navigate = useNavigate();

  const navigateToBom = (e) => {
    console.log(item);
    navigate('/bom', { state: { item } });
  }

  return (
    <div className='container mt-5'>
      <div className='item-searched-card' onClick={() => { navigateToBom() }} >
        <div className='row w-100 d-flex justify-content-center align-items-center'>
          <div className='items-data-container'>
            <div>
              <div className=''>
                <img src="https://t4.ftcdn.net/jpg/05/72/93/13/360_F_572931323_FKzYZwpTx8I1dO0q4Y8aKoOMNgSQQr69.jpg" className="item-image" />
              </div>
            </div>
            <div>
              <div className='item-data'>
                <h3>{item.name}</h3>
                {/* <h1>Rs: {item.price}</h1> */}
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Item
import './NavigationBar.css';
import { useState, useEffect, useRef } from 'react';
import logo from '../assest/logog.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';

function Navigationbar() {
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null); // Create a reference for the sidebar

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsOpen(false); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleClickOutside);
        document.addEventListener('mouseup', handleClickOutside);
        //document.addEventListener('', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sidebarRef]);

    return (
        <>
            <div className="navbar-container">
                <div className='d-flex justify-content-between align-item-center'>
                    <div>
                        <Link to="/" ><img src={logo} width={150} alt="logo" /></Link>
                    </div>
                    <button onClick={toggleNavbar} className="toggle-button">
                        {isOpen ? <IoClose className='icon-nav-close' />
                            : <GiHamburgerMenu className='icon-nav-menu' />}
                    </button>
                </div>
                <div ref={sidebarRef} className={`nav-links ${isOpen ? 'open' : ''}`}>
                    <Link to="/login">Login</Link>
                    {/* Add other links as needed */}
                </div>
            </div>
        </>
    );
}

export default Navigationbar;

import React, { useState } from 'react'
import './styles/login.css'
import user from '../assest/user.png'
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';

function Login() {

    const [userLogged,setUserLogged]=useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const navigate=useNavigate();

    const navigateToHome=()=>{
            navigate('/');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async  (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://127.0.0.1:8000/auth/', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data['status'] == 'success') {
                setUserLogged(true);
                navigateToHome();
                console.log("success login");
            }
            console.log('User login successfully:', response);
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
        console.log('Form submitted:', formData);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); 
    };

    return (
        <div>
            <div>
                <div className='login-screen-container'>
                    <div className='d-flex justify-content-center align-items-center mt-4'>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className='login-form'>
                                <form className='login-form' onSubmit={handleSubmit}>
                                    {/* <img src={user} width={100} /> */}
                                    <h2 className='my-4'>Sign in</h2>
                                    <div className='form-data mt-5'>
                                        <div className='form-group'>
                                            <input
                                                className='input-Box'
                                                type='text'
                                                id='email'
                                                name='email'
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                placeholder='email'
                                            />
                                        </div>
                                        <div className='form-group password-group'>
                                            <input
                                                className='input-Box'
                                                type={showPassword ? 'text' : 'password'}
                                                id='password'
                                                name='password'
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                                placeholder='Password'
                                            />
                                            <span className="eye-icon" onClick={togglePasswordVisibility}>
                                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>
                                    </div>
                                    <button type='submit' className='login-button mt-5'>
                                        Sign in
                                    </button>
                                    <div className='mt-5'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <h6>Not Account yet?</h6>
                                            <Link to="/register"><h6 className='mx-2'>Register</h6></Link>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <Link to="/password-reset" ><h6>Forgot your Password?</h6></Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
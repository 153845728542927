import React, { useState } from 'react'
import './styles/bomview.css'
import CarouselImage from '../component/CarouselImage'
import { useLocation } from 'react-router-dom';

function Bomview() {

  const [imageClick, setImageClick] = useState(false);
  const [clickedImage, setClickedImage] = useState({});
  const location = useLocation();
  // const { item } = location.state || {};

  const item = {
    productVersion: "v1.0",
    productName: "Durable Welcome Mat for Indoor and Outdoor Use - 30x17 Door Mat",
    brand: "HomeEssentials",
    manufacturer: "HomeEssentials Manufacturing Co.",
    certification: "Eco-Friendly, Non-Toxic",
    dimensions: "30 inches x 17 inches",
    minOrderQuantity: "50 units",
    seller: "HomeGoods Store",
    supplierAbility: "2000 units per month",
    processingTime: "5-10 business days",
    packageDetails: "Packaged flat with protective wrap to maintain shape during shipping.",
    youtubeLink:"https://www.youtube.com/embed/srU1VUOqAoQ",
    contents: [
      '1 x Durable Welcome Mat',
    ],
    features: [
      {
        featureName: 'Material',
        details: 'Made from weather-resistant coir and rubber for durability.',
      },
      {
        featureName: 'Non-Slip Backing',
        details: 'Rubberized backing to prevent slipping.',
      },
      {
        featureName: 'Easy to Clean',
        details: 'Can be easily cleaned by shaking out debris or rinsing with water.',
      },
      {
        featureName: 'Absorbent',
        details: 'Effectively traps dirt and moisture.',
      },
      {
        featureName: 'Design',
        details: 'Features a welcoming design suitable for indoor and outdoor use.',
      },
    ],
    instructions: `Place the mat at the entrance to keep your floors clean. Shake out regularly or rinse under water for easy maintenance.`,
  };
  

  const handleImageClick = (image) => {
    setImageClick(true);
    setClickedImage(image);
  }

  const images = [
    {
      url: 'https://m.media-amazon.com/images/I/81w-LdiGAQL._AC_SL1210_.jpg',
      label: 'Durable Welcome Mat',
      caption: 'Durable Welcome Mat for Indoor and Outdoor Use - 30x17 Door Mat',
    },
    {
      url: 'https://www.ikea.com/bh/en/images/products/toftbo-bath-mat-light-pink__1133090_pe878413_s5.jpg',
      label: 'Durable Welcome Mat',
      caption: 'Durable Welcome Mat for Indoor and Outdoor Use - 30x17 Door Mat',
    },
    {
      url: 'https://i.ebayimg.com/images/g/B-UAAOSwOgdgAXaB/s-l1200.jpg',
      label: 'Durable Welcome Mat',
      caption: 'Durable Welcome Mat for Indoor and Outdoor Use - 30x17 Door Mat',
    },
  ];


  return (
    <div className='container mt-4'>
      <div>
        <div className=''>
          <div className='bom-heading'>
            <h2>{item.productName}</h2>
          </div>
          <div className='bom-container'>
            <div className='image-container w-100'>
              <div className=' shadow imgs-area '>
                <div className='imgs-area2' >
                  <div className='image-slider shadow'>
                    {
                      imageClick ? (<>
                        <img src={clickedImage.url} className='image-clicked zoom-image' alt="Product Image"  />
                      </>) : (<><CarouselImage images={images} /></>)
                    }
                  </div>
                </div>
                <div>
                  <div className='d-flex align-items-center justify-content-left  p-2 my-2'>
                    {
                      images.map((img, index) => (
                        <div className='imgs-mini'>
                          <img src={img.url} className='item-imgs shadow' onClick={() => handleImageClick(img)} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='data-container'>
              <div className='item-data-container'>
                <div>
                  <div className='item-data-container m-5'>

                    <table className='item-data-table'>
                      <h2>{item.name}</h2>
                      <tbody>
                        <tr>
                          <td><strong>Product Version:</strong></td>
                          <td>{item.productVersion}</td>
                        </tr>
                        <tr>
                          <td><strong>Product Name:</strong></td>
                          <td>{item.productName}</td>
                        </tr>
                        <tr>
                          <td><strong>Brand:</strong></td>
                          <td>{item.brand}</td>
                        </tr>
                        <tr>
                          <td><strong>Manufacturer:</strong></td>
                          <td>{item.manufacturer}</td>
                        </tr>
                        <tr>
                          <td><strong>Certification:</strong></td>
                          <td>{item.certification}</td>
                        </tr>
                        <tr>
                          <td><strong>Product Dimensions:</strong></td>
                          <td>{item.dimensions}</td>
                        </tr>
                        <tr>
                          <td><strong>Minimum Order Quantity:</strong></td>
                          <td>{item.minOrderQuantity}</td>
                        </tr>
                        <tr>
                          <td><strong>Seller:</strong></td>
                          <td>{item.seller}</td>
                        </tr>
                        <tr>
                          <td><strong>Supplier Ability:</strong></td>
                          <td>{item.supplierAbility}</td>
                        </tr>
                        <tr>
                          <td><strong>Processing Time:</strong></td>
                          <td>{item.processingTime}</td>
                        </tr>
                        <tr>
                          <td><strong>Package Details:</strong></td>
                          <td>{item.packageDetails}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='product-info-card'>
              <h2>Product Review Details</h2>
              <p className='product-description'>{item.description}</p>

              <h4>Contents:</h4>
              <ul className='content-list'>
                <ul>
                  {item.contents && item.contents.length > 0 ? (
                    item.contents.map((content, index) => (
                      <li key={index}>{content}</li>
                    ))
                  ) : (
                    <li></li>
                  )}
                </ul>

              </ul>

              <h4>Fetures:</h4>
              <ul className='package-list'>
                <ul>
                  {item.features && item.features.length > 0 ? (
                    <>
                      {item.features.map((pack, index) => (
                        <li key={index}>
                          <strong>{pack.featureName}:</strong> {pack.details}
                        </li>
                      ))}
                    </>
                  ) : (
                    <li></li>
                  )}
                </ul>
              </ul>


              <h4>Instructions to Use:</h4>
              <p className='instructions'>{item.instructions}</p>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
      <div>
        <div className="video-container mt-5">
          <iframe
           className='ytv'
            src={item.youtubeLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default Bomview
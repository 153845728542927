import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import './styles/home.css';
import Item from '../component/Item';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems } from '../redux/actions/itemActions';
import Loder from '../component/Loder';

function Home() {

    const [isFixed, setIsFixed] = useState(false);
    const [search, setSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [searchedItems, setSearchedItems] = useState([]);
    const dispatch = useDispatch();

    const state = useSelector((state) => state);
    console.log(state);

    const { loading, items, error } = state.itemsData;

    useEffect(() => {
        dispatch(fetchItems());
        console.log(items);

    }, [dispatch]);

    const handleSearchClick = (e) => {
        e.preventDefault();
        setSearchTerm(inputValue);
        const filteredItems = items.filter(item =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
            // ||
            // item.description.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSearch(true);
        setSearchedItems(filteredItems);
        setIsFixed(true);
    };


  
    useEffect(() => {
        const element = document.querySelector('.text-to-animate');
        element.classList.add('typing-effect');
    }, []);

    return (
        <div>
            <div className='d-flex justify-content-center align-items-center h-100'>
                <div className={`search-group ${isFixed ? 'fixed-search' : ''}`}>
                    <div className='text-center my-2 opacity-75'>
                        <p className='text-to-animate text-center my-2 opacity-50'>Empowering a clean, connected world</p>
                    </div>
                    <div className={`d-flex justify-content-center align-item-center`}>
                        <div>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className='searchBox-text-box'
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                />
                            </form>
                        </div>
                        <div className='searchBox-bg' onClick={handleSearchClick}>
                            <FaSearch className='icon' />
                        </div>   
                    </div> 
                    {searchTerm && search ? (
                         <div className='mt-4 text-center'>
                            <p className='searched-text my-4'>
                                Results For <b>{searchTerm}</b>
                             </p> 
                        </div>
                    ) : null}
                </div>
            </div>

            <div>{loading ? (<>
                <div className='d-flex justify-content-center align-items-center'>
                    <Loder/>
                </div>
            </>) : (<><div className='searched-cards'>
                {searchTerm.trim() !== "" && searchedItems.length > 0 ? (
                    searchedItems.map((item, index) => (
                        <div key={index}>
                            <Item item={item} />
                        </div>
                    ))
                ) : searchedItems.length === 0 || searchTerm ? (
                    <p className={`text-center mt-5 ${isFixed ? '' : 'display-none'}`}>No results found</p>
                ) : null}
            </div></>)}

            </div>
        </div>
    );
}

export default Home;

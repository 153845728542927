// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import itemReducer from './reducers/itemReducer';

const store = configureStore({
    reducer: {
        itemsData: itemReducer,
    },
});

export default store;
